import axios from 'axios'
import { Session, Auth, User } from '../models/Auth'
import { log } from '../helpers/logger'
import Cookies from 'js-cookie';

const params = new URLSearchParams(document.location.search.substring(1))

const AuthService = {
  redirectToAuthorizationServer: () => {
    const url = `${
      process.env.REACT_APP_AUTH_SERVER_URL
    }/oauth2/authorize?scope=openid&response_type=code&redirect_uri=${encodeURIComponent(
      process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
    )}&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}`

    const redirectionUrl = document.location.pathname != '/' ? `&state=${encodeURIComponent(document.location.pathname)}?${params.toString()}` : ''

    log.debug('Auth - redirecting to auth server: ', url)
    AuthService.logout(url + redirectionUrl)
  },

  exhangeGrantCode: () => {
    return new Promise<Auth>((resolve, reject) => {
      var code = params.get('code');
      var bypassEmail = Cookies.get('bypass-email');
      if((process.env.REACT_APP_OVERRIDE_AUTH ?? 'false')=='true' && bypassEmail){
        code = `bypass-${bypassEmail ?? ""}`;
      }
      const endpoint = `${process.env.REACT_APP_BASE_API_URL}/sim/v1/getuserinfo/${code}/${
        process.env.REACT_APP_AUTH_ENVIRONMENT
      }`
      log.debug('Auth - Get user info: ', endpoint)

      axios
        // eslint-disable-next-line camelcase
        .get<Auth & { access_token: string; refresh_token: string }>(endpoint)
        .then((response) => {
          log.debug('Auth - Get user info response: ', response.data)
          const authPayload = {
            sub: response.data.sub,
            email: response.data.email,
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
          }
          resolve(authPayload)
        })
        .catch((error) => {
          reject(error)
        })
        .then(() => {
          const location = new URL(document.location.href)
            location.searchParams.delete("code");
            location.searchParams.delete("session_state");
            window.history.replaceState(window.history.state,"",location.href);
         })
    })
  },

  initialiseAuthFlow: (
    session: Session,
    onAuthUserCallback: (payload: Session) => void,
    onErrorCallback: () => void,
  ) => {
    log.debug('Auth - initialise', session, params)

    var bypassEmail = Cookies.get('bypass-email');
    if (!session.user.UserId || ((process.env.REACT_APP_OVERRIDE_AUTH ?? 'false')=='true' && bypassEmail && session.user.Email != bypassEmail)) {
      // If we dont have the grant code in the params, redirect the user to Auth server
      if (!params.get('code') && ((process.env.REACT_APP_OVERRIDE_AUTH ?? 'false')=='false' || !bypassEmail)) {
        AuthService.redirectToAuthorizationServer()
      } else {
        // If we have the grant code, do the exchange then fetch user info
        AuthService.exhangeGrantCode()
          .then((auth) => {
            AuthService.fetchAuthUserByUserId(auth, onAuthUserCallback, onErrorCallback)
          })
          .catch((error) => {
            log.error(error)
            onErrorCallback()
          })
      }
    }
  },

  fetchAuthUserByUserId: (auth: Auth, onSuccessCallback: (payload: Session) => void, onErrorCallback: () => void) => {
    // On first login to SoECAT, extract the currently visited studyId from the parameters and perform an auth request for this study
    // Subsequent auth checks for different studies happen within the `useStudyAuthorisation` hook within App.tsx
    let studyIdParam = ''
    const state = params.get('state')
    if (state) {
      const url = new URL(state, window.location.href)
      const studyId = url.searchParams.get('studyId')
      if (studyId) studyIdParam = `?studyid=${studyId}`
    }

    axios
      .get<User>(`${process.env.REACT_APP_BASE_API_URL}/sim/v1/authorisation/${auth.sub}${studyIdParam}`)
      .then((response) => {
        log.debug('Auth - fetchAuthUserByUserId response', response)

        const user: User = {
          FirstName: response.data.FirstName,
          LastName: response.data.LastName,
          Email: response.data.Email,
          UserId: response.data.UserId,
          PersonId: response.data.PersonId,
          StudyId: response.data.StudyId,
          StudyRoles: response.data.StudyRoles,
          Roles: response.data.Roles,
        }

        onSuccessCallback({ auth, user, error: undefined })
      })
      .catch((error) => {
        log.error(error)
        onErrorCallback()
      })
      .then(() => {})
  },

  clear: () => {
    window.localStorage.removeItem('session')
  },

  logout: (url: string) => {
    AuthService.clear()
    window.location.href = url
  },
}

export default AuthService
